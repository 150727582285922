<script>
import useVuelidate from "@vuelidate/core";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import "@bhplugin/vue3-datatable/dist/style.css";

export default {
  components: {
    Layout,
    PageHeader,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      exams: [],
      examNotes: [],
      teacher: {},
      data: {
      },
      page: 1,
      perPage: 5,
      pages: [],
      submited: false,
      spanView: false,
      title: "Nouveau Tuteur",
      searchQuery: "", // added for search functionality
      items: [
        { text: "Tuteurs", href: "/" },
        { text: "Nouveau", active: true },
      ],
    };
  },

  mounted() {
    this.fetchClassExams();
  },

  computed: {
    displayedPosts() {
      return this.paginate(this.exams);
    },

    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter(data => {
          return [
            data.id,
            data.name,
            data.email,
            data.position,
            data.company,
            data.country
          ].some(field => field?.toLowerCase().includes(search));
        });
      }
      return this.displayedPosts;
    },
  },

  methods: {
    async fetchClassExams() {
      const route = `/api/classExams/${this.$route.params.filiereId}`;
      try {
        const response = await this.$store.dispatch("getRequest", { route, data: this.data });
        this.exams = response.data;
        this.updatePages();
      } catch (error) {
        console.error(error);
      }
    },

    async fetchExamNotes(id) {
      const route = `/api/exam/${id}`;
      try {
        const response = await this.$store.dispatch("getRequest", { route, data: this.data });
        this.examNotes = response.data.grades.map((elmt) => ({
          note: elmt.note,
          matricule: elmt.etudiant?.matricule,
          student: elmt.etudiant?.nom + " " + elmt.etudiant?.prenom,
          apreciation: elmt.apreciation
        }));
      } catch (error) {
        console.error(error);
      }
    },

    updatePages() {
      this.pages = [];
      const numberOfPages = Math.ceil(this.exams.length / this.perPage);
      for (let i = 1; i <= numberOfPages; i++) {
        this.pages.push(i);
      }
    },

    paginate(data) {
      const start = (this.page - 1) * this.perPage;
      return data.slice(start, start + this.perPage);
    },

    navToStudentsNotes(id) {
      this.fetchExamNotes(id);
    },
  },
};
</script>

<style scoped>
#number {
  height: 39px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" v-model="searchQuery" class="form-control" placeholder="Search..." />
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th scope="col">Matière</th>
                  <th scope="col">Type d'évaluation</th>
                  <th scope="col">Semestre/trimestre</th>
                  <th scope="col">Coeffiscient</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td>{{ data.course?.nom_cours }}</td>
                  <td>{{ data.type_examen }}</td>
                  <td>{{ data.semestre }}</td>
                  <td>{{ data.course?.credits }}</td>
                  <td>
                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"
                      @click="navToStudentsNotes(data.id)" class="link-success fs-15">
                      <i class="ri-eye-line"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <form >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                   Notes des élèves
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <table class="table align-middle table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Matricule</th>
                        <th scope="col">Etudiant</th>
                        <th scope="col">Note</th>
                        <th scope="col">Appréciation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="note in examNotes" :key="note.id">
                        <th scope="row">{{ note.matricule }}</th>
                        <td>{{ note.student}}</td>
                        <td>
                          <input class="form-control-sm" type="number" v-model="note.note" />
                        </td>
                        <td>
                          <select class="form-control-sm" v-model="note.apreciation">
                            <option value="mauvais">Mauvais</option>
                            <option value="passable">Passable</option>
                            <option value="assez bien">Assez bien</option>
                            <option value="bien">Bien</option>
                            <option value="tres bien">Très bien</option>
                            <option value="excellent">Excéllent</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
